import classNames from 'classnames';

import { getVerticalModuleClassName, getVerticalName } from 'lib/verticalType';

import { getPrimaryProductOfferPricesAndAvailability } from 'lib/productOfferPriceHelpers';

// Test that the content object has a teaseImage with a valid url.
export const hasTeaseImage = (content) => {
  let url = false;
  if (content.alternateTeaseImages?.length) {
    url = content?.alternateTeaseImages?.[0]?.image?.url ?? false;
  }
  if (!url && content.teaseImage) {
    url = content?.teaseImage?.url ?? false;
  }
  return url?.primary && /^http/.test(url.primary);
};

// Get tease image data from the content object.
export const getTeaseObject = (content) => {
  if (content.alternateTeaseImages?.length) {
    // Variable aspect ratios
    return content.alternateTeaseImages;
  }
  if (content.teaseImage) {
    // Legacy
    return content.teaseImage;
  }
  // Not found
  return null;
};

export const getComputedTease = (content, square = false) => {
  const teases = getTeaseObject(content);
  if (Array.isArray(teases)) {
    const ratio = square ? '1X1' : '2X1';
    return teases.find((t) => t.aspectRatio.search(ratio) !== -1);
  }
  return teases;
};

export const getTeaseForRatio = (content, square = false) => {
  const tease = getComputedTease(content, square);
  if (tease?.image) {
    return tease.image;
  }
  return tease;
};

export const getTeaseByline = (tease) => {
  if (!tease) {
    return '';
  }

  const { authors, source } = tease;
  const authorName = (authors?.[0]?.name) || '';
  const sourceName = (source?.name) || '';

  if (authorName && sourceName) {
    return `${authorName} / ${source.name}`;
  }

  return authorName || sourceName;
};

const teaseImageUrl = (tease) => {
  const value = ((tease || {}).url || {}).primary || '';
  return value.replace(/\s/gi, '%20');
};

export const getTeaseUrl = (content, square = false) => {
  if (content.computedValues) {
    const { computedValues } = content;
    let url = null;

    // alternateTeaseImages with variable aspect ratios.
    if (computedValues.alternateTeaseImages?.length) {
      const tease = getTeaseForRatio(computedValues, square);
      url = teaseImageUrl(tease);
    }

    // Legacy singular teaseImage.
    if (computedValues.teaseImage && !url) {
      url = teaseImageUrl(computedValues.teaseImage);
    }

    // No image.
    return url;
  }

  // Super legacy tease image.
  const { coverImage, mainImage, type } = content;
  if (type === 'slideshow') {
    const [slide] = content.slides;
    if (!slide.image) {
      return '';
    }
    return (coverImage || mainImage || slide.image || {}).url || '';
  }
  return (coverImage || mainImage || {}).url || '';
};

export const getTeasePicture = (
  computedValues,
  fallbackImageUrlMap,
  vertical = 'news',
  aspectRatio = 'ASPECT_2X1',
) => {
  if (!hasTeaseImage(computedValues)) {
    Object.assign(computedValues, {
      alternateTeaseImages: [
        {
          aspectRatio,
          image: {
            altText: vertical,
            url: {
              primary: fallbackImageUrlMap[vertical.toLowerCase()],
            },
          },
        },
      ],
    });
  }
  return computedValues;
};

export const getDek = (props) => {
  const { card } = props;
  const { computedValues } = card || {};
  return (computedValues?.dek) || '';
};

export const isThinkModule = (content) => {
  const verticalName = getVerticalName(content);
  return classNames({
    [getVerticalModuleClassName(verticalName)]: verticalName === 'think',
  });
};

export const getInfoForShopButtons = (props) => {
  const { card } = props;
  const {
    item,
    name: productName,
    offers,
    productDescription,
  } = card.item || card;
  const itemOffers = item?.offers;
  const { externalUrl, seller } = (offers?.length && offers[0])
    || (itemOffers?.length && itemOffers[0])
    || {};
  const productUrl = (externalUrl || (seller?.externalUrl)) || '';
  const { sale, list, state } = getPrimaryProductOfferPricesAndAvailability(card);

  return {
    list,
    state,
    productDescription,
    productName,
    productUrl,
    sale,
    seller,
  };
};

export const getInfoForSpecificShopButtons = (card, offer) => {
  const {
    name: productName,
    productDescription,
  } = card.item || card;
  const { externalUrl, seller } = offer
    || {};
  const productUrl = (externalUrl || (seller?.externalUrl)) || {};
  const { sale, list, state } = getPrimaryProductOfferPricesAndAvailability(card);

  return {
    list,
    state,
    productDescription,
    productName,
    productUrl,
    sale,
    seller,
  };
};

export const getUniformTeaseItem = (item) => {
  const { type } = item;

  // Item from curation
  if (typeof item.computedValues !== 'undefined') {
    return {
      ...item,
      authors: item?.item?.authors ?? [],
      datePublished: item?.item?.datePublished,
      description: item?.computedValues?.dek,
      headline: item?.computedValues?.headline,
      tease: getTeaseUrl(item),
      type,
      unibrow: item?.computedValues?.unibrow,
      url: item?.computedValues?.url,
      vertical: item?.item?.taxonomy?.primaryVertical?.name,
    };
  }

  // Item from content object
  return {
    ...item,
    authors: item?.authors ?? [],
    datePublished: item?.datePublished,
    description: item?.description?.primary,
    headline: item?.headline?.primary,
    tease: teaseImageUrl(item.teaseImage),
    type,
    unibrow: item?.unibrow,
    url: item?.url?.primary,
    vertical: item?.vertical,
  };
};

export const getIsNative = (vertical, content) => {
  const { type, item } = content;
  const nativeContentTypes = ['article', 'video'];
  const isSponsored = vertical !== 'sponsoredcontent';
  const { nativeAd } = item || content;
  return nativeContentTypes.includes(type) && !!nativeAd && !isSponsored;
};
