import AIMS_FLAVORS from 'lib/aimsFlavors';

// Checks that the URL contains formats that need to be converted
const IS_IMAGE_REGEX = /(.*?)\/(i|j)\/(.*)\/(.*)(jpg|jpeg|gif|png|bmp)$/i;

export const isImage = (url) => typeof url === 'string' && url.match(IS_IMAGE_REGEX) !== null;

const getAimsSignatureForFlavor = (flavor, { width, height }) => {
  switch (flavor) {
    case AIMS_FLAVORS.FIT:
      return `${flavor}-${width}w`;
    case AIMS_FLAVORS.FOCAL:
      return `${flavor}-${width}x${height}`;
    default:
      return flavor;
  }
};

export function getAimsUrlFlavor(url, { width = 800, height = 320, flavor = AIMS_FLAVORS.FOCAL }) {
  if (!url || !isImage(url)) {
    return null;
  }

  const signature = getAimsSignatureForFlavor(flavor, { width, height });

  return url
    .replace(/^http:/, 'https:')
    .replace(/\/i\//, '/j/')
    .replace(/\.(jpg|jpeg|png|gif|bmp)/i, `.${signature}.$1`);
}

// https://nbcnewsdigital.atlassian.net/wiki/spaces/NEWSDEVICE/pages/38600882/AIMS+Developer+Guide
export function getAimsUrlSuffix(url, {
  width = 640,
  height = 480,
  resample = 7,
  compression = 70,
  mode = 3,
}) {
  if (!url || !isImage(url)) {
    return null;
  }

  // Resampling algorithm is a value from 0 to 7. Use 7 for best results.
  const r = [1, 2, 3, 4, 5, 6, 7].indexOf(resample) >= 0 ? resample : 7;

  // Compression ratio is a value from 0 to 100.
  const c = !Number.isNaN(compression) && compression >= 0 && compression <= 100 ? compression : 70;

  // 1 - Box (scales to fit, then centers and adds black bars to fill the rest of the space)
  // 2 - Center crop
  // 3 - Scale to fit
  // 4 - T-crop (scales to fill and centers on the upper portion of the image)
  // 5 - Smart T-crop (crops to the subject of the image, such as a person's face)
  const m = [1, 2, 3, 4, 5].indexOf(mode) >= 0 ? mode : 3;

  const signature = `${width};${height};${r};${c};${m}`;

  return url
    .replace(/^http:/, 'https:')
    .replace(/\/i\//, '/j/')
    .replace(/\.(jpg|jpeg|png|gif|bmp)/i, `.${signature}.$1`);
}

export default getAimsUrlFlavor;
