export const isAimsSourceImage = (url) => {
  const aimsRegex = /s-nbcnews\.com\/i/i;
  return aimsRegex.test(url);
};

export const isAimsUrl = (url) => {
  const aimsRegex = /s-nbcnews\.com\/j/i;
  return aimsRegex.test(url);
};

// Convert aims url back to the source.
export const getAimsSourceImage = (url) => {
  if (!isAimsUrl(url)) {
    return url;
  }

  return url.replace(/com\/j\//, 'com/i/')
    .replace(/\.[^/]+\.(?=jpe*g|gif|png)/i, '.');
};
