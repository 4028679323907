import React from 'react';
import PropTypes from 'prop-types';

import { children as ChildrenPropType } from 'lib/CustomPropTypes';

import Link from './Link';

/**
 * Simple wrapper around the {@link Link} component. If `props.href` is truthy,
 * wrap children in a {@link Link} component, forwarding all props. Otherwise,
 * if `props.href` is falsey, just return the children on their own.
 * @param {object} props React props
 * @param {string} [props.href] URL which is checked for truthiness
 * @param {object} [props.children] React children for content of link tag
 */
export function LinkIfHref(props) {
  const { children, href } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return href ? <Link {...props} /> : children;
}

LinkIfHref.propTypes = {
  icid: PropTypes.string,
  children: ChildrenPropType,
  href: PropTypes.string,
  className: PropTypes.string,
  deviceUrl: PropTypes.string,
  domain: PropTypes.string,
  host: PropTypes.string,
};

LinkIfHref.defaultProps = {
  children: null,
  href: null,
  className: null,
  deviceUrl: null,
  domain: null,
  host: null,
  icid: null,
};
