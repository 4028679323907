import getAimsUrlFlavor, { isImage } from './getAimsUrl';


const focalDimensionRegex = /(\d*)x(\d*)/;

const fitDimensionRegex = /fit-(\d*)w/;

const focalDimensionReplace = (flavor) => (width, height) => (
  flavor.replace(focalDimensionRegex, `${width}x${height}`)
);

const fitDimensionReplace = (flavor) => (width) => (
  flavor.replace(fitDimensionRegex, `fit-${width}w`)
);


const isNbcHostedUrlImage = (url) => {
  if (!url) {
    return false;
  }
  const nbcHostedRegex = /s-nbcnews\.com/i;
  return nbcHostedRegex.test(url);
};

const isTelemundoHostedImage = (url) => {
  if (!url) {
    return false;
  }
  const telemundoHostedRegex = /\/nbcutelemundo/i;
  return telemundoHostedRegex.test(url);
};

/**
 * Given a URL determine if it comes from Cloudinary
 * @param {string} url
 * @returns {boolean}
 */
export const isCloudinaryImage = (url) => {
  if (typeof url !== 'string') {
    return false;
  }

  return url.includes('media-cldnry') || url.includes('cloudinary.com');
};

/**
 * Given a URL determine if is resizable. Any image which comes from a hosted
 * provider that we support (AIMS, Telemundo, Cloudinary) will be resizable.
 * @param {string} url
 * @returns {boolean}
 */
export const isResizableImage = (url) => (
  isNbcHostedUrlImage(url) || isTelemundoHostedImage(url) || isCloudinaryImage(url)
);

export const getTelemundoImageSrc = (originalSource, flavor) => {
  const newSource = originalSource
    .replace(/sites\/nbcutelemundo\/files/, `sites/nbcutelemundo/files/styles/${flavor}/public`);
  return `${newSource}?ramen_itok=iqwQftIcTf`;
};

// Match cloudinary rendition between slashes: /t_fit-1240w,f_auto,q_auto:best/
export const regexCloudinaryRendition = new RegExp('/(t_[^/]+)/');

/**
 * Given a source URL from Cloudinary generate a URL with the flavor applied.
 * @param {string} originalSrc
 * @param {string} flavor
 * @param {string} cloudinaryImgQuality
 * @param {boolean|number} scaleFactor
 * @returns {string}
 */
export const getCloudinaryImageSrc = (originalSrc, flavor, cloudinaryImgQuality = 'best', scaleFactor) => {
  const imageQuality = scaleFactor > 1 ? 'eco' : cloudinaryImgQuality; // for retina screens always use eco quality

  const validScalefactor = Number(scaleFactor) > 1;
  const dpr = validScalefactor ? `,dpr_${Number(scaleFactor)}` : '';

  // Use avif for images with a scaleFactor.
  const format = validScalefactor ? 'f_avif' : 'f_auto';
  const rendition = `/t_${flavor},${format},q_auto:${imageQuality}${dpr}/`;

  // Replace existing rendition
  if (regexCloudinaryRendition.test(originalSrc)) {
    return originalSrc.replace(regexCloudinaryRendition, rendition);
  }

  // Add new image rendition
  return originalSrc.replace('/image/upload/', `/image/upload${rendition}`);
};

/**
 * Adds a flavor to Cloudinary URLs.
 *
 * @param {string} url An image url.
 * @param {string} flavor A Cloudinary flavor.
 * @returns {string} A flavored image URL or the unprocessed image if it is not a Cloudinary one.
 */
export const getCloudinaryFlavoredImage = (url, flavor) => (
  isCloudinaryImage(url)
    ? getCloudinaryImageSrc(url, flavor)
    : url
);

/**
 * @param {string} flavor
 */
export const getWidthAndHeightFromAIMSFlavor = (flavor) => {
  if (typeof flavor === 'string') {
    const focalResult = flavor.match(focalDimensionRegex);
    if (focalResult) {
      const [, width, height] = focalResult;
      return {
        height,
        width,
        replace: focalDimensionReplace(flavor),
      };
    }

    const fitResult = flavor.match(fitDimensionRegex);
    if (fitResult) {
      const [, width] = fitResult;
      const height = width; // Responsive - see fit AIMS definitions
      return {
        height,
        width,
        replace: fitDimensionReplace(flavor),
      };
    }

    return {};
  }
  return {};
};

export const IMAGE_SIZES = [
  's',
  'm',
  'l',
  'xl',
];

export const BREAKPOINT_SIZE_MAP = {
  s: 320,
  m: 758,
  l: 1000,
  xl: 1240,
};

/**
 * Get the optimized image url
 * @param {string} url
 * @param {string} flavor
 */
export const getOptimizedImageUrl = (url, flavor, ...args) => {
  if (isCloudinaryImage(url)) {
    if (args.length) return getCloudinaryImageSrc(url, flavor, ...args);
    return getCloudinaryImageSrc(url, flavor);
  }
  if (isTelemundoHostedImage(url)) {
    return getTelemundoImageSrc(url, flavor);
  }
  if (isImage(url)) {
    return getAimsUrlFlavor(url, { flavor });
  }
  return url;
};

/**
 * Image Qualities for Cloudinary
 * https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
 */
export const CLAUDINARY_QUALITIES = {
  best: 'best',
  good: 'good',
  eco: 'eco',
  low: 'low',
};
