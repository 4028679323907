export function imageAltForImage(image) {
  const altText = image?.altText ?? '';
  const caption = image?.caption ?? '';
  return altText || caption || '';
}

export function imageAltForItem(item) {
  const { computedValues } = item;
  if (computedValues) {
    if (item.type === 'video') {
      return computedValues.headline;
    } if (computedValues.teaseImage) {
      return imageAltForImage(computedValues.teaseImage);
    }
  }
  return '';
}
