import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { responsiveFlavors as ResponsiveFlavorsPropType } from 'lib/CustomPropTypes';
import { getWidthAndHeightFromAIMSFlavor } from 'lib/imageUtils';
import styles from './styles.module.scss';

const Placeholder = ({
  className,
  responsiveFlavors,
  color,
}) => {
  if (!responsiveFlavors) return null;

  const mobileIndex = 0;

  const sizeKeys = Object.keys(responsiveFlavors);
  const lastIndex = sizeKeys.length - 1;

  const placeholders = sizeKeys.map((sizeKey, sizeIndex) => {
    const size = responsiveFlavors[sizeKey];
    const { width, height } = getWidthAndHeightFromAIMSFlavor(size);
    const ratio = Math.round((height / width) * 100);

    const classes = [{
      // Is larger than mobile, display:none mobile and display:block at bp
      [`dn db-${sizeKey}`]: sizeIndex > mobileIndex,

      // Check that this size is not last bp in the array and display:none for the 'next' bp
      [`dn-${sizeKeys[sizeIndex + 1]}`]: sizeIndex !== lastIndex,
    }];

    let inlineStyle = null;
    if ([100, 50, 56].includes(ratio)) {
      // 1x1, 2x1, and 16x9 images
      classes.push(styles[`r${ratio}`]);
    } else {
      // Use inline style on unknown image ratio
      inlineStyle = {
        paddingTop: `${ratio}%`,
      };
    }

    // Add placeholder
    return (
      <div
        key={sizeKey}
        className={classNames(classes)}
        style={inlineStyle}
        data-bp={sizeKey}
      />
    );
  }, []);

  return (
    <div
      className={classNames(
        className,
        { [`bg-${color}`]: color },
      )}
      data-test="picture__placeholder"
      data-testid="picture__placeholder"
    >
      {placeholders}
    </div>
  );
};

Placeholder.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  responsiveFlavors: ResponsiveFlavorsPropType,
  color: PropTypes.string,
};

Placeholder.defaultProps = {
  className: null,
  responsiveFlavors: null,
  color: 'knockout-secondary',
};

export { Placeholder };
