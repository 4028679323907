import React, { useState } from 'react';
import PropTypes from 'prop-types';


import { ENABLE_RETINA } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

import {
  responsiveFlavors as responsiveFlavorsPropType,
  computed as ComputedPropType,
} from 'lib/CustomPropTypes';
import { VerticalContext } from 'lib/ContextTypes';

import { getTeaseUrl } from 'lib/teaseUtils';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { BREAKPOINT_SIZE_MAP, CLAUDINARY_QUALITIES } from 'lib/imageUtils';
import { imageAltForItem } from 'lib/imageAlt';
import { imageLoaded, getFormattedImgSrc } from 'lib/PictureUtils';

import { Placeholder } from './Placeholder';
import { PreloadImages } from './Preload';

/**
 * Creates the <source /> elements for <picture />
 */
const SrcSetSource = ({
  retina, responsiveFlavors, flavor, computedValues, url, cloudinaryImgQuality,
}) => {
  const sizes = ['xl', 'l', 'm', 's'];

  const srcset = sizes.map((size) => {
    let src = getFormattedImgSrc({
      size, responsiveFlavors, flavor, computedValues, url, cloudinaryImgQuality,
    });

    if (src) {
      // We dont want to use retina for gifs.
      const isGif = src.endsWith('.gif');
      if (retina && !isGif) {
        // Make sure both 1x and 2x AIMS flavors exist
        src = `${getFormattedImgSrc({
          size,
          responsiveFlavors,
          flavor,
          computedValues,
          url,
          scaleFactor: 2,
          cloudinaryImgQuality,
        })} 2x, ${src} 1x`;
      }
      return (
        <source
          media={`(min-width: ${BREAKPOINT_SIZE_MAP[size]}px)`}
          srcSet={src}
          key={BREAKPOINT_SIZE_MAP[size] + src}
        />
      );
    }
    return null;
  });

  return srcset;
};

SrcSetSource.propTypes = {
  retina: PropTypes.bool,
  flavor: PropTypes.string,
  responsiveFlavors: responsiveFlavorsPropType,
  computedValues: ComputedPropType,
  url: PropTypes.string,
  cloudinaryImgQuality: PropTypes.oneOf(Object.values(CLAUDINARY_QUALITIES)),
};

SrcSetSource.defaultProps = {
  retina: false,
  flavor: AIMS_FLAVORS.FOCAL,
  responsiveFlavors: {
    s: AIMS_FLAVORS.FOCAL,
    m: AIMS_FLAVORS.FOCAL,
    l: AIMS_FLAVORS.FOCAL,
    xl: AIMS_FLAVORS.FOCAL,
  },
  computedValues: null,
  url: null,
};

/**
 * Builds the necessary components to render an image
 * @param {Object} props - Object containing all data passed from the SlideshowImage component
 * @param {string} url - src value for the image
 * @param {string} alt - alt param value for the <img> element
 * @param {string} className - all class names that should be attached to the element
 * @param {string} flavor - used for specifying the retina value in preload
 * @param {responsiveFlavors} responsiveFlavors - values used to resolve the url
 * @param {bool} placeholder - whether or not to display a place holder
 * @param {string} placeholderColor - must be a color NAME (ex. "red", "blue", "HotPink"). Used in the placeholder component to set its background color.
 * @param {number} originalHeight - natural HEIGHT of the image, used innately by the browser to maintain aspect ratio
 * @param {number} originalWidth - natural WIDTH of the image, used innately by the browser to maintain aspect ratioe
 * @param {bool} lazyloaded - whether or not to lazy load
 * @param {function} onLoad - function to run once the image is loaded
 * @param {computed} computedValues - used in getting the TeaseURL and an Alt value when one isn't provided
 * @param {bool} retina - used in getSrcSet for scaling images on 2x devices
 * @param {bool} preload - decides whether to force a preload by appending to head. Should probs default to true
 * @param {string} cloudinaryImgQuality - quality of the image to be loaded
 * @returns {React.ReactElement}
 */
const Picture = ({
  url,
  alt,
  className,
  flavor,
  responsiveFlavors,
  placeholder,
  placeholderColor,
  originalHeight,
  originalWidth,
  lazyloaded,
  computedValues,
  retina,
  preload,
  onLoad,
  cloudinaryImgQuality,
  testId,
}) => {
  const vertical = React.useContext(VerticalContext);
  const [error, setImageError] = useState(false);

  const hasURL = url || getTeaseUrl({ computedValues });

  const enableRetina = getFeatureConfigForBrand(
    ENABLE_RETINA,
    vertical,
  ) || retina;

  if (placeholder && (error || !hasURL)) {
    return (
      <Placeholder
        className={className}
        responsiveFlavors={responsiveFlavors}
        color={placeholderColor}
      />
    );
  }

  const hidden = error ? { visibility: 'hidden' } : {};
  const loading = lazyloaded ? 'lazy' : undefined;
  const altText = computedValues ? imageAltForItem({ computedValues }) : alt;

  let imgHeight;
  let imgWidth;
  if (originalHeight && originalWidth) {
    imgHeight = originalHeight;
    imgWidth = originalWidth;
  }

  return (
    <>
      {preload && (
        <PreloadImages
          responsiveFlavors={responsiveFlavors}
          flavor={flavor}
          computedValues={computedValues}
          url={url}
          cloudinaryImgQuality={CLAUDINARY_QUALITIES[cloudinaryImgQuality]}
          enableRetina={enableRetina}
        />
      )}
      <picture
        className={className}
        style={hidden}
        data-testid={testId || 'picture'}
      >
        <SrcSetSource
          retina={retina || enableRetina}
          responsiveFlavors={responsiveFlavors}
          flavor={flavor}
          computedValues={computedValues}
          url={url}
          cloudinaryImgQuality={CLAUDINARY_QUALITIES[cloudinaryImgQuality]}
        />
        <img
          loading={loading}
          src={getFormattedImgSrc({
            size: 's',
            responsiveFlavors,
            flavor,
            computedValues,
            url,
            cloudinaryImgQuality: CLAUDINARY_QUALITIES[cloudinaryImgQuality],
          })}
          alt={altText}
          onLoad={() => imageLoaded({ onLoad })}
          onError={() => setImageError(true)}
          height={imgHeight}
          width={imgWidth}
        />
      </picture>
    </>
  );
};

Picture.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  flavor: PropTypes.string,
  responsiveFlavors: responsiveFlavorsPropType,
  placeholder: PropTypes.bool,
  placeholderColor: PropTypes.string,
  originalHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  originalWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lazyloaded: PropTypes.bool,
  computedValues: ComputedPropType,
  retina: PropTypes.bool,
  preload: PropTypes.bool,
  onLoad: PropTypes.func,
  cloudinaryImgQuality: PropTypes.oneOf(Object.values(CLAUDINARY_QUALITIES)),
  testId: PropTypes.string,
};


Picture.defaultProps = {
  url: null,
  alt: '',
  className: '',
  flavor: AIMS_FLAVORS.FOCAL,
  responsiveFlavors: {
    s: AIMS_FLAVORS.FOCAL,
    m: AIMS_FLAVORS.FOCAL,
    l: AIMS_FLAVORS.FOCAL,
    xl: AIMS_FLAVORS.FOCAL,
  },
  placeholder: false,
  placeholderColor: 'knockout-secondary',
  originalHeight: null,
  originalWidth: null,
  lazyloaded: false,
  computedValues: null,
  retina: false,
  preload: false,
  onLoad: null,
  cloudinaryImgQuality: CLAUDINARY_QUALITIES.BEST,
  testId: null,
};

export { Picture };
