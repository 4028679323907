import { getTeaseUrl } from 'lib/teaseUtils';

// TODO: [BENTO-9041] combine dynamic image resize utils/libs (AIMS)
import { getAimsUrlFlavor } from 'lib/getAimsUrl';
import { getAimsSourceImage } from 'lib/isAimsUrl';

import { AIMS_FLAVOR_VALUES } from 'lib/aimsFlavors';
import {
  getTelemundoImageSrc,
  getWidthAndHeightFromAIMSFlavor,
  getCloudinaryImageSrc,
  isCloudinaryImage,
} from 'lib/imageUtils';

/**
 * Get properly formatted URL
 */
const getUrl = ({
  size,
  computedValues,
  url,
  responsiveFlavors,
}) => {
  const useTelemundoUrl = typeof url === 'string' && url.includes('telemundo.com');

  if (computedValues) {
    const flavor = responsiveFlavors ? responsiveFlavors[size] : '';
    const { width, height } = getWidthAndHeightFromAIMSFlavor(flavor);
    const isSquare = width && height && (width === height);
    return getTeaseUrl({ computedValues }, isSquare, useTelemundoUrl);
  }

  if (useTelemundoUrl || isCloudinaryImage(url)) {
    return url;
  }

  return getAimsSourceImage(url);
};

/**
 * when an invalid flavor is passed in we make it known via console.warn
 */
const warnFlavorDoesNotExist = (flavor, isRetina = false) => {
  const message = isRetina
    ? `Retina flavor ${flavor} doesn't exist, falling back to non-retina image`
    : `Flavor ${flavor} doesn't exist`;
  // eslint-disable-next-line no-undef
  if (!__TEST__ && __CLIENT__) {
    // eslint-disable-next-line no-console
    console.warn(message);
  }
};


/**
* Tests given url against different formats and param values and returns a properly resolved url for the given scenario.
* if the url cannot be resolved it will return null, this can occur with getTeaseUrl();
*/
export const getFormattedImgSrc = ({
  size,
  responsiveFlavors,
  flavor,
  computedValues,
  url,
  scaleFactor = null,
  cloudinaryImgQuality,
}) => {
  let srcFlavor = flavor;

  if (responsiveFlavors[size]) {
    if (!AIMS_FLAVOR_VALUES.includes(srcFlavor)) {
      warnFlavorDoesNotExist(srcFlavor);
    }

    const fetchedUrl = getUrl({
      size,
      computedValues,
      url,
      responsiveFlavors,
    });

    if (fetchedUrl) {
      srcFlavor = responsiveFlavors[size];

      if (isCloudinaryImage(fetchedUrl)) {
        return getCloudinaryImageSrc(fetchedUrl, srcFlavor, cloudinaryImgQuality, scaleFactor);
      }

      const dimensions = getWidthAndHeightFromAIMSFlavor(srcFlavor);
      if (scaleFactor && dimensions.width && dimensions.height && dimensions.replace) {
        const height = dimensions.height * scaleFactor;
        const width = dimensions.width * scaleFactor;
        const retinaFlavor = dimensions.replace(width, height);

        // Check if the retina flavor exists
        if (AIMS_FLAVOR_VALUES.includes(retinaFlavor)) {
          srcFlavor = retinaFlavor;
        } else {
          warnFlavorDoesNotExist(retinaFlavor, true);
        }
      }
      // end scalefactor
      if (fetchedUrl.includes('telemundo.com')) {
        return getTelemundoImageSrc(fetchedUrl, srcFlavor);
      }
      return getAimsUrlFlavor(fetchedUrl, { flavor: srcFlavor });
    }
  }
  return null;
};
