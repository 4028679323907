/**
 * Extract the primaryVertical taxonomy value from a given cotent object
 * @param {object} article
 * @returns string
 */
export const getVerticalName = (article) => {
  if (!article) {
    return '';
  }

  const { item } = article;

  if (!item) {
    return '';
  }

  // Required, as item can be undefined, and hence object destructuring syntax will not work.
  const taxonomy = item?.taxonomy;

  return (taxonomy?.primaryVertical?.name ?? '').toLowerCase();
};

/**
 * Format a vertical string value into a vertical module string value
 * @param {string} vertical
 * @returns string
 */
export const getVerticalModuleClassName = (vertical) => (
  vertical && vertical.length > 0
    ? `${vertical}-module`
    : ''
);
