import url from 'url';
import { getDomain, getSubdomain } from 'tldjs';
import { stripTrailingSlash } from 'lib/urlUtils';

const isSameDomain = (linkUrl, currentDomain, currentHost) => {
  const linkDomain = getDomain(linkUrl);
  const linkSubdomain = getSubdomain(linkUrl);

  // no domain, assuming the path is relative
  if (!linkDomain) {
    return true;
  }

  // comparing domains and ignoring subdomains
  if (!currentHost || !linkSubdomain || linkSubdomain === 'www') {
    return currentDomain === linkDomain;
  }

  // if host is provided, compare subdomains as well
  // i.e community.today.com should be treated as an external link
  // if we are on www.today.com
  // ignoring localhost.today.com and *.newsapp.today.com
  const currentSubdomain = getSubdomain(currentHost);
  const regExpressionSubdomain = /^[a-z0-9-]+.newsapp/g;

  // ignoring subdomains for currentDomain
  if (currentSubdomain === 'localhost' || currentSubdomain?.match(regExpressionSubdomain)) {
    return currentDomain === linkDomain;
  }

  return currentDomain === linkDomain && currentSubdomain === linkSubdomain;
};

const getRelativePathBasedOnDomain = (linkUrl, currentDomain, currentHost) => {
  if (!linkUrl || !currentDomain) {
    return null;
  }

  // Relative link or link's domain matches local domain
  if (isSameDomain(linkUrl, currentDomain, currentHost)) {
    const parsed = url.parse(linkUrl);

    if (parsed.hash) {
      parsed.pathname = `${parsed.pathname}${parsed.hash}`;
    }

    // Strip trailing slash
    const pathname = parsed.pathname && parsed.pathname.length > 1
      ? stripTrailingSlash(parsed.pathname)
      : parsed.pathname;

    return parsed.search ? `${pathname}${parsed.search}` : pathname;
  }

  return null;
};

export default getRelativePathBasedOnDomain;
